import Roblox, { EnvironmentUrls } from 'Roblox';

import { httpService } from '../http/http';

import {
  activeEvents,
  defaultActivityTimeoutMs,
  defaultHeartbeatPulseIntervalMs,
  guacUrlSuffix
} from './constants/constants';

class PageHeartbeatScheduler {
  lastActiveTime = new Date();

  heartbeatCount = 1;

  heartbeatPulseIntervalMs = 0;

  activityTimeoutMs = 0;

  constructor(heartbeatPulseIntervalMs: number, activityTimeoutMs: number) {
    this.heartbeatPulseIntervalMs = heartbeatPulseIntervalMs;
    this.activityTimeoutMs = activityTimeoutMs;
  }

  onActiveEvent() {
    this.lastActiveTime = new Date();
  }

  start() {
    setTimeout(() => this.onTimeout(), this.heartbeatPulseIntervalMs);
  }

  onTimeout() {
    const currentTime = new Date();
    const thresholdTime = new Date(currentTime.getTime() - this.activityTimeoutMs);

    if (this.lastActiveTime >= thresholdTime) {
      Roblox.EventStream.SendEventWithTarget(
        'pageHeartbeat_v2',
        `heartbeat${this.heartbeatCount}`,
        {},
        Roblox.EventStream.TargetTypes.WWW
      );
      this.heartbeatCount += 1;
    }

    setTimeout(() => this.onTimeout(), this.heartbeatPulseIntervalMs);
  }
}

type GuacResponse = {
  isEnabled?: boolean;
  rolloutPermille?: number;
  activityTimeoutMs?: number;
  heartbeatPulseIntervalMs?: number;
};

type GuacConfig = {
  isEnabled: boolean;
  rolloutPermille: number;
  activityTimeoutMs: number;
  heartbeatPulseIntervalMs: number;
};

async function loadGuacConfig(): Promise<GuacConfig> {
  const { apiGatewayUrl } = EnvironmentUrls;
  try {
    const config = await httpService.get({
      url: `${apiGatewayUrl}${guacUrlSuffix}`
    });

    const data = config?.data as GuacResponse;

    if (!data) {
      return {
        isEnabled: false,
        rolloutPermille: 0,
        activityTimeoutMs: defaultActivityTimeoutMs,
        heartbeatPulseIntervalMs: defaultHeartbeatPulseIntervalMs
      };
    }

    return {
      isEnabled: Boolean(data.isEnabled),
      rolloutPermille: data.rolloutPermille ?? 0,
      activityTimeoutMs: data.activityTimeoutMs ?? defaultActivityTimeoutMs,
      heartbeatPulseIntervalMs: data.heartbeatPulseIntervalMs ?? defaultHeartbeatPulseIntervalMs
    };
  } catch (e) {
    console.error(e);

    return {
      isEnabled: false,
      rolloutPermille: 0,
      activityTimeoutMs: defaultActivityTimeoutMs,
      heartbeatPulseIntervalMs: defaultHeartbeatPulseIntervalMs
    };
  }
}

export default async function pageHeartbeatInit(): Promise<void> {
  const config = await loadGuacConfig();

  if (
    !(
      config.isEnabled &&
      Roblox.CurrentUser?.userId &&
      parseInt(Roblox.CurrentUser.userId, 10) % 1000 < config.rolloutPermille
    )
  ) {
    return;
  }

  const scheduler = new PageHeartbeatScheduler(
    config.heartbeatPulseIntervalMs,
    config.activityTimeoutMs
  );

  activeEvents.forEach(eventType => {
    window.addEventListener(eventType, () => {
      scheduler.onActiveEvent();
    });
  });

  scheduler.start();
}
